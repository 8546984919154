import { FlexBox } from "@ui/FlexBox";
import { IconBig } from "@ui/IconBig";
import { Text, TextMedium } from "@ui/Text";
import { Children, FC } from "react";
import { useTheme } from "styled-components";
import { IPageStatusMessageProps } from "./types";

export const PageStatusMessage: FC<IPageStatusMessageProps> = (props) => {
    const theme = useTheme();
    const { children, description, message, iconBigProps, small, type, messageProps, ...restProps } = props;

    return (
        <FlexBox alignItems="center" flexDirection="column" justifyContent="center" {...restProps}>
            <IconBig small={small} type={type} {...iconBigProps} />
            <Text
                as="h2"
                mt={small ? theme.legacySpacing(3) : theme.legacySpacing(4)}
                mb={
                    !description && !!children && Children.toArray(children).length > 0
                        ? small
                            ? theme.legacySpacing(3)
                            : theme.legacySpacing(4)
                        : undefined
                }
                style={{ maxWidth: "100%" }}
                textVariant={small ? "medium" : "h5"}
                textAlign="center"
                textFontWeight="medium"
                {...messageProps}
            >
                {message}
            </Text>
            {!!description && (
                <TextMedium
                    mb={
                        !!children && Children.toArray(children).length > 0
                            ? small
                                ? theme.legacySpacing(3)
                                : theme.legacySpacing(4)
                            : undefined
                    }
                    mt={small ? theme.legacySpacing(1) : theme.legacySpacing(2)}
                    style={{ maxWidth: "100%" }}
                    textAlign="center"
                    textColor="textMuted"
                >
                    {description}
                </TextMedium>
            )}
            {children}
        </FlexBox>
    );
};
