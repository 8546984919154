import { routeToUrl } from "@app-routes";
import { NextPage } from "@app-types/next";
import { Button } from "@ui/Button";
import { Grid } from "@ui/Grid";
import { SplashScreen } from "@ui/Layout/SplashScreen/SplashScreen";
import { PageMenuItem } from "@ui/PageMenu/PageMenuItem";
import { Text } from "@ui/Text";

interface IOwnProps {
    errorMessage?: string;
    statusCode?: null | number;
}

const Error: NextPage<IOwnProps> = (props) => {
    const { statusCode, errorMessage } = props;
    return (
        <SplashScreen
            ogData={{
                ogTitle: statusCode ? String(statusCode) : "Page not found",
                description: errorMessage || "Sorry, but the page is not available",
            }}
            description={
                errorMessage || statusCode === 404
                    ? "Dear users, due to the new data structure, your original links may no longer work. Your content can be found on the new sousede.cz portal. Just log in again."
                    : "The page is not available."
            }
            message={statusCode ? (statusCode === 404 ? "The page is not available." : statusCode) : "Page not found"}
            pageName={String(statusCode)}
            title="An error has occurred"
            type="sad"
        >
            {statusCode === 404 && (
                <>
                    <Text as="p" textAlign="center" mb={20} textVariant="medium" textColor="textMuted">
                        Maybe you were looking for one of these pages?
                    </Text>
                    <Grid
                        gridAutoRows="1fr"
                        alignItems="center"
                        gridColumnCount={[2, 4]}
                        mb={20}
                        gridGap={16}
                        justifyContent="center"
                    >
                        <PageMenuItem icon="house" href={routeToUrl("house-zone/house-community")}>
                            Moje domy
                        </PageMenuItem>
                        <PageMenuItem icon="users" href={routeToUrl("club-zone/community")}>
                            Komunita
                        </PageMenuItem>
                        <PageMenuItem icon="magazine" href={routeToUrl("magazine-zone/home")}>
                            Magazín
                        </PageMenuItem>
                        <PageMenuItem icon="market" href={routeToUrl("market-zone/market")}>
                            Tržiště
                        </PageMenuItem>
                    </Grid>
                </>
            )}
            <Button kind="muted" href={routeToUrl("index")} width={320}>
                Back to the main page
            </Button>
        </SplashScreen>
    );
};

Error.getInitialProps = (ctx) => {
    const { res, err } = ctx;
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return { statusCode };
};

export default Error;
