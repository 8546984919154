import { Box } from "@ui/Box";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { ICircleProps } from "./types";

export const Circle = styled(Box)<ICircleProps>(
    ({ size, theme }) => css`
        align-items: center;
        background-color: ${theme.color.circle.light.background("primary")[0]};
        border-radius: ${withUnit(theme.borderRadius.full, "px")};
        display: flex;
        flex: 0 0 auto;
        height: ${size ? rem(theme.size.circle[size].radius) : null};
        justify-content: center;
        position: relative;
        text-align: center;
        width: ${size ? rem(theme.size.circle[size].radius) : null};
    `,
);
