import { useTranslation } from "@translations/use-translation";
import { Container } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Layout } from "@ui/Layout/Layout";
import { PageStatusMessage } from "@ui/PageStatusMessage/PageStatusMessage";
import { FC, PropsWithChildren } from "react";
import { ISplashScreenProps } from "./types";

export const SplashScreen: FC<PropsWithChildren<ISplashScreenProps>> = (props) => {
    const { t } = useTranslation();
    const {
        children,
        description = t("ui-layout-splash-screen:moment"),
        iconBigProps,
        message = t("ui-layout-splash-screen:loading"),
        small,
        type = "loading",
        messageProps,
        ...restProps
    } = props;
    return (
        <Layout backgroundColor="white" hideFooterVisually hideNavBar hideTools {...restProps}>
            <FlexBox alignItems="center" flexGrow="1" flexShrink="1" justifyContent="center">
                <Container flexShrink="0" maxWidth={468} pb={[48, null, 100]} pt={48}>
                    <PageStatusMessage
                        description={description}
                        iconBigProps={{ ...iconBigProps }}
                        message={message}
                        small={small}
                        type={type}
                        messageProps={messageProps}
                    >
                        {children}
                    </PageStatusMessage>
                </Container>
            </FlexBox>
        </Layout>
    );
};
