import {
    DISABLED_OPACITY,
    FOCUS_VISIBLE_CLASSNAME,
    IS_HOVERABLE_CLASSNAME,
    LINK_HOVER_OPACITY,
} from "@styles/constants";
import { Box, IBoxProps } from "@ui/Box";
import { Circle, ICircleProps } from "@ui/Circle";
import { NewClickable } from "@ui/NewClickable";
import { ITextProps, TextMedium } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { spacing } from "@uxf/styles/units/spacing";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { RootProps } from "./types";

export const DesktopMenu = styled(Box)<IBoxProps>(
    ({ theme }) => css`
        & + * {
            margin-top: ${rem(spacing(1))};

            ${Responsive.min(theme.breakpoint.xl)} {
                margin-top: ${rem(spacing(2))};
            }
        }
    `,
);

export const ItemCircle = styled(Circle)<ICircleProps>``;
export const ItemTitle = styled(TextMedium)<ITextProps>``;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Root = styled(({ variant, ...restProps }: RootProps) => <NewClickable {...restProps} />)<RootProps>(
    ({ variant, theme }) => css`
        align-items: center;
        border-radius: ${rem(theme.borderRadius.default)};
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        justify-content: flex-start;
        padding: ${rem(8)};
        text-align: center;
        width: 100%;

        ${ItemCircle} {
            transition: ${CssHelper.transition("backgroundColor")};
        }

        ${ItemTitle} {
            transition: ${CssHelper.transition("opacity")};
        }

        &:focus {
            outline-style: none;
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                ${ItemCircle} {
                    background-color: ${variant ? theme.color.circle.light.background(variant)[1] : null};
                }

                ${ItemTitle} {
                    opacity: ${LINK_HOVER_OPACITY};
                }
            }
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            box-shadow: ${theme.shadow.buttonFocus};
            position: relative;
            z-index: 4;
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            pointer-events: none;
        }
    `,
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HorizontalRoot = styled(({ variant, ...restProps }: RootProps) => (
    <NewClickable {...restProps} />
))<RootProps>(
    ({ variant, theme }) => css`
        align-items: center;
        background-color: ${theme.color.palette.white};
        border-radius: ${rem(theme.borderRadius.default)};
        border: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-start;
        padding: ${rem(12)} ${rem(16)};
        text-align: left;
        width: 100%;

        ${ItemCircle} {
            flex: 0 0 auto;
            margin-right: ${rem(12)};
            transition: ${CssHelper.transition("backgroundColor")};

            ${Responsive.min(theme.breakpoint.sm)} {
                height: ${rem(theme.size.circle.size48.radius)};
                width: ${rem(theme.size.circle.size48.radius)};
            }
        }

        ${ItemTitle} {
            flex: 1 1 auto;
            min-width: 0;
            transition: ${CssHelper.transition("opacity")};
        }

        &:focus {
            outline-style: none;
        }

        &.${IS_HOVERABLE_CLASSNAME} {
            &:hover,
            &:active {
                ${ItemCircle} {
                    background-color: ${variant ? theme.color.circle.light.background(variant)[1] : null};
                }

                ${ItemTitle} {
                    opacity: ${LINK_HOVER_OPACITY};
                }
            }
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            box-shadow: ${theme.shadow.buttonFocus};
            position: relative;
            z-index: 4;
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            pointer-events: none;
        }
    `,
);
