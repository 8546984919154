import { FC } from "react";
import { ItemCircle, ItemTitle, Root } from "./styles";
import { PageMenuItemComponentProps } from "./types";

export const PageMenuItem: FC<PageMenuItemComponentProps> = (props) => {
    const { children, icon, iconTitle, variant = "primary", href, badge, badgeProps, ...restProps } = props;
    return (
        <Root variant={variant} href={href} {...restProps} data-testid={href ? `page-menu-item-${href}` : undefined}>
            <ItemCircle
                className="circle"
                iconTitle={iconTitle}
                light
                name={icon}
                size="size48"
                variant={variant}
                badge={badge}
                badgeProps={badgeProps}
            />
            <ItemTitle as="div" className="text" textFontWeight="medium" mt={8}>
                {children}
            </ItemTitle>
        </Root>
    );
};
